import React, { useRef } from "react";
import Slider from "react-slick";
import styles from "./Slider.module.scss";
import Layout from "../../layouts/Layout/Layout";
import { Button } from "../../elements";
import { ButtonS } from "../../elements";
import photo from '../../../assets/placeholders/photo.svg';
import THEME from "../../../state/theme";

export default ({ data = [] }) => {
  let sliderRef = useRef(null);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const slideList = data.map((item, index) => (
    <article key={index}>
      <Layout
        style={{
          background: "url(" + ") center/cover",
        }}
        col="1"
        mode="light"
      >
        <div className={styles.slide} style={{color: THEME.blue_structurea}}>
        { /* <i
            className={"las la-long-arrow-alt-left"}
            onClick={() => sliderRef.slickPrev()}
          />*/}

          <div className={styles.intro}>
            {/* <h1>{item.title}</h1>*/}
            <img src={photo} alt="background" />
            <p>{item.short}</p>
            <ButtonS to="portfolio" type="solid-color-tw" hoverType="outline_color_tw" edge>
              EN SAVOIR PLUS
            </ButtonS>
          </div>
        {/*  <i
            className={"las la-long-arrow-alt-right"}
            onClick={() => sliderRef.slickNext()}
          />*/}
        </div>
      </Layout>
    </article>
  ));

  return (
    <Slider ref={(slider) => (sliderRef = slider)} {...settings}>
      {slideList}
    </Slider>
  );
};
