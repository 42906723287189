import React from "react";
import styles from "./Subscribe.module.scss";
import { Layout } from "../../layouts";
import { Subscribe, Logo } from "../../elements";
import THEME from "../../../state/theme";
import { useCustomState } from "../../../state/state";
import logo_symbole from '../../../assets/images/logo_symbole.svg';

export default () => {
  const state = useCustomState()[0];
  return (
    <Layout
      col="1"
      mode="light"
      padding
      blur
      style={{
        background:THEME.background
      }}
    >
      <div className={styles.subscribe} style={{ color: THEME.blue_structurea} }>
        <span className="subtitle" style={{ color: THEME.color }}>
          Newsletter
        </span>
        <h2>Restez au courant de notre activité.</h2>
        <Subscribe />


      </div>

      <div className={styles.logo}>
        <Logo logo={logo_symbole}/>
      </div>
    </Layout>
  );
};
