import React, { useState } from "react";
import { Link as LinkS } from "react-scroll";
import styles from "./LinkS.module.scss";

export default ({ children, url, hoverStyle, style, click, after }) => {
  const [hover, setHover] = useState(false);

  return (
    <LinkS
      to= {url}
      smooth={true}
      duration={500}
      spy={true}
      exact='true'
      offset={-80}
      activeClass={styles.active}
      onMouseEnter={() => setHover(!hover)}
      onMouseLeave={() => setHover(!hover)}
      style={hover ? hoverStyle : style}
      onClick={click}
      data-after={after}
    >
      {children}
    </LinkS>
  );
};
