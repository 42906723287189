import THEME from "../state/theme";

export function registerNewsLetter(email){

  return new Promise((resolve,reject) => {
    fetch( THEME.base_url + 'registerNewsletter.php',{
      method: 'post',
      mode: 'cors',
      headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
          },
      body: `email=${email}`})
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
          reject(error);
    });
  });

}
