import React, { Fragment } from "react";
import {
  Features,
  Features2,
  Subscribe,
  Services,
  Team,
  Services3,
  Contacts2,
  Slider,
  Reviews,
  Facts,
  Header,
  Project,
  Portfolio2,
  Subscribe2
} from "../../widgets";


import { useCustomState } from "../../../state/state";

export default () => {
  const state = useCustomState()[0];

  return (
    <Fragment>
          <Slider data={state.data.posts} />
          <Services3 data={state.data.services} />
          <Project data={state.data.portfolio} clients={state.data.reviews} />
          {/*<Features2 data={state.data.features2} />
          <Team data={state.data.team} />
        <Facts data={state.data.facts} />
          <Services data={state.data.services} />
          {/*<Features data={state.data.features} />*/}
          <Contacts2 />
          <Subscribe />
        {  /*<Reviews data={state.data.reviews} />*/}

    </Fragment>
  );
};
