import React, { useState } from "react";
import emailjs from "emailjs-com";
import styles from "./ContactForm.module.scss";
import { CardButton } from "../../ui";
import { Spinner } from "../../elements";
import {sendEmail} from "../../../utilities/sendEmail";
import { useCustomState } from "../../../state/state";

export default ({ style }) => {
  const [sending, setSending] = useState(false);
  const [status, setStatus] = useState("");
  const [error, setError] = useState(false);
  const [state, actions] = useCustomState();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [preferedCheck, setPreferedCheck] = useState(false);

  const templateParams = {
    name: name,
    email: email,
    phone: phone,
    message: message,
    email_prefered: preferedCheck ? "Oui" : "Non",
  };

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const onSubmit = () => {
    if (name === "") {
      setStatus("Svp veuillez entrer votre nom");
      setError(true);
      return;
    }

    if (!validateEmail(email)) {
      setStatus("Email incorrect");
      setError(true);
      return;
    }

    if (message === "") {
      setStatus("Message Vide. Svp veuillez entrer un message");
      setError(true);
      return;
    }

    setSending(true);
    // emailjs
    //   .send(
    //     "SERVICE ID",
    //     "TEMPLATE ID",
    //     templateParams,
    //     "USER ID"
    //   )
    //   .then(
    //     (response) => {
    //       setSending(false);
    //       setError(false);
    //       setStatus("Your message has been sent!");
    //     },
    //     (err) => {
    //       setSending(false);
    //       setError(true);
    //       setStatus("Sorry. There is an error: " + err.text);
    //     }
    //   );

    sendEmail(name, email, phone, message, preferedCheck ? "Oui" : "Non").then((result) => {
      setSending(false);
        console.log(result);
      if(result.status.toLowerCase() === "success"){
        //actions.toogleModal();
        setStatus("Votre message a été bien envoyé");
        setError(false);
        console.log("Envoi réussi");
      } else {
        setStatus("Echec d'envoi. Veuillez réessayer");
        setError(true);
        console.log("Echec d'envoi");
      }
    })
    .catch(error => {
      setSending(false);
      console.log("error with sending email" + error);
    });

    resetForm();
  };

  const resetForm = () => {
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
    setPreferedCheck(false);
  };

  const loading = sending && (
    <div className={styles.loading}>
      <Spinner />
    </div>
  );

  return (
    <form style={style} className={styles.wrapper}>
      {loading}
      <CardButton
        click={onSubmit}
        btn_after=""
        btn_text="ENVOYER"
        btn_type="solid-color-tw"
        btn_hoverType="solid-gray-tb"
        btn_align="stretch"
        padding
      >
        <div className={styles.contact_form}>
          <span className={[styles.status, error && styles.error, !error && styles.success].join(" ")}>
            {status}
          </span>
          <h4>Nous envoyer un message:</h4>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            placeholder="Nom *"
          />
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Email *"
          />
          <input
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            type="text"
            placeholder="Téléphone"
          />
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows="10"
            placeholder="Tapez votre message *"
          />
          <label className={styles.checkbox_group}>
            <p>Préférence de contact par email</p>
            <input
              onChange={() => setPreferedCheck(!preferedCheck)}
              type="checkbox"
              checked={preferedCheck}
            />
            <span className={styles.checkmark} />
          </label>
        </div>
      </CardButton>
    </form>
  );
};
