import React, { useState } from "react";
import emailjs from "emailjs-com";
import styles from "./Subscribe.module.scss";
import { Button } from "../../elements";
import {registerNewsLetter} from "../../../utilities/registerNewsLetter";

export default () => {
  const [sending, setSending] = useState(false);
  const [status, setStatus] = useState("");

  const [email, setEmail] = useState("");

  const templateParams = {
    email: email,
    request: "Subscription",
  };

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const onSubmit = () => {
    if (!validateEmail(email)) {
      setStatus("Incorrect email address");
      return;
    }

    setSending(true);
    // emailjs
    //   .send("SERVICE ID", "TEMPLATE ID", templateParams, "USER ID")
    //   .then(
    //     (response) => {
    //       setSending(false);
    //       setStatus("Your message has been sent!");
    //     },
    //     (err) => {
    //       setSending(false);
    //       setStatus("Sorry. There is an error: " + err.text);
    //     }
    //   );

    registerNewsLetter(email).then((result) => {
      setSending(false);
        console.log(result);
      if(result.status.toLowerCase() === "success"){
        //actions.toogleModal();
        setStatus("Vous avez bien été enregistré pour la Newsletter.");
        console.log("Envoi réussi");
      } else {
        setStatus("Echec d'envoi. Veuillez réessayer");
        console.log("Echec d'envoi");
      }
    })
    .catch(error => {
      setSending(false);
      console.log("error with sending email" + error);
    });
    setEmail("");
  };

  return (
    <form className={styles.subscribe}>
      <span className={styles.status}>{sending ? "Sending... " : status}</span>
      <input
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        type="text"
        placeholder="Votre email"
      />
    <Button click={onSubmit} hoverType="outline-color-tb" type="solid-color-tw" before="&#xf105;" />
    </form>
  );
};
