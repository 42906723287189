import React, { Fragment } from "react";
import styles from "./Contacts2.module.scss";
import Layout from "../../layouts/Layout/Layout";
import { ContactForm } from "../../ui";
import { AddressBox, Button } from "../../elements";
import THEME from "../../../state/theme";
import { useCustomState } from "../../../state/state";

export default () => {
  const actions = useCustomState()[1];

  return (
    <Fragment>
      <div id="contacts" className={styles.header} style={{ background: THEME.background, color:THEME.blue_structurea}}>
        <span className="subtitle" style={{ color: THEME.color}}>
          CONTACT
        </span>
        <h2>N'hésitez pas à nous contacter dans le cadre de vos projets</h2>
        <p>
          Nous faisons notre possible pour les prendre en charge.
        </p>
      </div>
      <Layout col="3">
        <AddressBox
          icon="las la-phone"
          title="Téléphone"
          text1="+32.477.42.98.04"
          text2="+352.691.320.730"
        />
        <AddressBox
          icon="la la-envelope-open"
          title="E-Mail"
          text1="info@structurea.be"
          text2="info@structurea.lu "
        />
        <AddressBox
          icon="la la-home"
          title="Adresse"
          text1="BE: Rue de Louveigné, 188 C21, 4052 Beaufays"
          text2="LU: 62, Av. de la Liberté L-1930 Luxembourg"
        />
      {/*<AddressBox
          icon="las la-comment"
          title="Support"
          text="Live chart 24/7"
        />*/}
        <span className={styles.divider}  />
      </Layout>



      <Layout col="2">

        <div className={styles.wrapper} style={{ color: THEME.blue_structurea }}>
          <ContactForm  />
        </div>


        <div className={styles.info} style={{ color: THEME.blue_structurea }}>
          {/*<i
            className="las la-long-arrow-alt-right"
            style={{ color: THEME.color }}
          />*/}
        <h2>Prêt à collaborer avec nous?</h2>
          <p>
            Contacter nous par message ou par téléphone.<br></br>
          Nous sommes également actifs sur LinkedIn.
          </p>

          <Button
            click={() =>
              window.open("https://www.linkedin.com/company/structurea-sprl/about/")
            }
            type="outline-color-tb"
            hoverType="outline-color-tb"
            edge
          >
            LINKEDIN
          </Button>
        </div>

      </Layout>

    </Fragment>
  );
};
